
    import { defineComponent } from 'vue'
    import Heading from './Heading.vue'
    import DynamicContent from './DynamicContent.vue'

    export default defineComponent({
        name: 'Notice',
        components: {
            Heading,
            DynamicContent
        },
        props: {
            title: { type: String, required: true },
            description: { type: Object, required: true }
        }
    })

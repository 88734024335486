
    import { defineComponent, onBeforeMount, ref } from 'vue'
    import Grid from '../components/Grid/Grid.vue'
    import Column from '../components/Grid/Column.vue'
    import Container from '../components/Container.vue'
    import DynamicContent from '../components/DynamicContent.vue'
    import Heading from '../components/Heading.vue'
    import Notice from '../components/Notice.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Picture from '../components/Picture.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import TitleAndList from '../components/TitleAndList.vue'
    import { useTheme } from '../compositions/UseTheme'
    import { useApi } from '../compositions/UseApi'
    import { Theme } from '../enums/Theme'
    import { useRoute } from 'vue-router'
    import { useCms } from '../compositions/UseCms'
    import { isCNLocale } from '../compositions/useI18n'

    export default defineComponent({
        name: 'OpenSourceSingle',
        components: {
            Grid,
            Column,
            Container,
            DynamicContent,
            Heading,
            Notice,
            Paragraph,
            Picture,
            MetaInfo,
            ThemeChanger,
            TitleAndList
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()
            const route = useRoute()

            const packageData = ref<any>({})
            const isLoading = ref(true)
            const isFailed = ref(false)

            onBeforeMount(async() => {

                const id = route.params.slug as string

                const response = await useApi().openSourcePackage(id, isCNLocale.value ? 'cn' : 'en')

                if (response.isOk()) {

                    isLoading.value = false
                    packageData.value = response.value.data

                }

                if (response.isErr()) {

                    isLoading.value = false
                    isFailed.value = true

                }

            })

            return {
                package: packageData,
                isLoading,
                isFailed,
                isCNLocale,
                Theme
            }

        }
    })

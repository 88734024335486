import { ref, watchEffect } from 'vue'

const isReady = ref(false)

document.addEventListener('WeixinJSBridgeReady', () => (isReady.value = true), false)

export function useWechatBridge() {

    return {
        onBridgeReady(callback: () => void) {

            watchEffect(() => {

                if (isReady.value) {

                    callback()

                }

            })

        }
    }

}

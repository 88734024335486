import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border border-pink-300 py-12 px-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_DynamicContent = _resolveComponent("DynamicContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Heading, {
      tag: "h3",
      class: "notice__header",
      innerHTML: _ctx.title
    }, null, 8, ["innerHTML"]),
    _createVNode(_component_DynamicContent, {
      class: "pt-8",
      content: _ctx.description
    }, null, 8, ["content"])
  ]))
}
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["srcset", "alt", "title", "type"]
const _hoisted_3 = ["loading", "src", "title", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoopingVideo = _resolveComponent("LoopingVideo")!
  const _component_Paragraph = _resolveComponent("Paragraph")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative w-full h-full text-center picture", { 'overflow-hidden': !_ctx.overflow }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative flex w-full h-full text-center", { 'overflow-hidden': !_ctx.overflow, 'justify-center': _ctx.center }]),
      style: _normalizeStyle(_ctx.rootElementStyle)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["picture__placeholder", { '--exit': _ctx.imageLoaded }]),
        style: _normalizeStyle(_ctx.blurryImage),
        onTransitionend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ontransitionend && _ctx.ontransitionend(...args)))
      }, null, 38),
      (_ctx.image)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.image?.desktop_asset?.is_video && !_ctx.isVideo)
              ? (_openBlock(), _createElementBlock("picture", _hoisted_1, [
                  (_ctx.image?.mobile_asset)
                    ? (_openBlock(), _createElementBlock("source", {
                        key: 0,
                        media: "(max-width: 767px)",
                        srcset: _ctx.image?.mobile_asset?.permalink,
                        alt: _ctx.alt ?? _ctx.image?.desktop_asset?.alt,
                        title: _ctx.alt ?? _ctx.image?.desktop_asset?.alt,
                        type: _ctx.image?.mobile_asset?.mime_type
                      }, null, 8, _hoisted_2))
                    : _createCommentVNode("", true),
                  _createElementVNode("img", {
                    class: _normalizeClass({ 'object-cover': _ctx.cover }),
                    loading: _ctx.lazy ? 'lazy' : null,
                    style: _normalizeStyle(_ctx.rootElementStyle),
                    src: _ctx.image?.desktop_asset?.permalink ?? _ctx.image?.permalink,
                    title: _ctx.alt ?? _ctx.image?.desktop_asset?.alt,
                    alt: _ctx.alt ?? _ctx.image?.desktop_asset?.alt,
                    onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoaded && _ctx.onLoaded(...args)))
                  }, null, 46, _hoisted_3)
                ]))
              : (_openBlock(), _createBlock(_component_LoopingVideo, {
                  key: 1,
                  class: "w-full h-full",
                  source: _ctx.image?.desktop_asset.permalink,
                  cover: _ctx.image?.video_cover
                }, null, 8, ["source", "cover"]))
          ], 64))
        : _createCommentVNode("", true)
    ], 6),
    (_ctx.image?.caption || _ctx.$slots.caption)
      ? (_openBlock(), _createBlock(_component_Paragraph, {
          key: 0,
          class: "my-5 leading-none picture__caption"
        }, {
          default: _withCtx(() => [
            (_ctx.image?.caption)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.image.caption), 1)
                ], 64))
              : _renderSlot(_ctx.$slots, "caption", { key: 1 })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 2))
}


    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import Picture from './Picture.vue'
    import { ImageInterface } from '../Interfaces/ImageInterface'
    import { useWechatBridge } from '../compositions/UseWechatBridge'

    export default defineComponent({
        name: 'LoopingVideo',
        components: { Picture },
        props: {
            source: { type: String, required: true },
            cover: { type: Object as PropType<ImageInterface> },
            fullWidth: { type: Boolean, default: false },
            defaultGradient: { type: String, default: null }
        },
        setup() {

            /**
             * Once wechat is ready auto play video
             */
            const videoReference = ref<HTMLVideoElement | null>()
            const { onBridgeReady } = useWechatBridge()

            onMounted(() => {

                onBridgeReady(() => {

                    if (videoReference.value) {

                        videoReference.value.play()

                    }

                })

            })

            const loading = ref(true)

            return {
                loading,
                videoReference,
                onLoaded() {

                    loading.value = false

                }
            }

        }
    })

